// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import "./js/core/popper.min"
// import "./js/core/bootstrap.min"
import * as bootstrap from "./js/core/bootstrap.bundle.min"
import "./js/plugins/perfect-scrollbar.min"
import "./js/plugins/smooth-scrollbar.min"
// import "./js/soft-ui-dashboard.js"

import 'toastr/build/toastr.min.css';
import toastr from 'toastr';

window.bootstrap = bootstrap;
window.toastr = toastr;

let modal = null;

window.showModal = () => {
  var modalElement = document.getElementById("dynamicModal");
  if (modalElement) {
    modal = new bootstrap.Modal(modalElement);
    modal.show();
  }
};

window.hideModal = () => {
  if (modal) {
    modal.hide();
  }
};
